<template>
    <div>
      <div class="dashboard-container">
        <!-- 상단 아이콘 링크 영역 제거 -->
        
        <!-- 스케줄 달력 -->
        <div class="card" style="width: 100%;">
          <div class="card-body">
            <h5 class="card-title">일정표</h5>
            <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;">
              <button type="button" class="btn btn-primary btn-sm" @click="addSchedule">일정 추가</button>
            </h6>
            <div class="calendar-container">
              <div class="calendar-header">
                <button class="btn btn-sm btn-outline-primary" @click="prevMonth">&lt;</button>
                <h3>{{ currentMonthYear }}</h3>
                <button class="btn btn-sm btn-outline-primary" @click="nextMonth">&gt;</button>
              </div>
              <div class="calendar-days">
                <div class="weekday" v-for="day in weekdays" :key="day">{{ day }}</div>
              </div>
              <div class="calendar-dates">
                <div 
                  v-for="date in calendarDates" 
                  :key="date.date" 
                  class="date-cell" 
                  :class="{ 
                    'other-month': !date.currentMonth, 
                    'has-events': hasEvents(date.date),
                    'today': isToday(date.date)
                  }"
                  @click="date.currentMonth && viewDateEvents(date.date)"
                >
                  <div class="date-number">{{ date.day }}</div>
                  <div class="event-dots" v-if="hasEvents(date.date)">
                    <div 
                      v-for="(event, idx) in getDateEvents(date.date)" 
                      :key="idx" 
                      class="event-dot" 
                      :style="{ background: getCategoryColor(event.category) }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p></p>

      <!-- 공지사항-->
      <div class="card" style="width: 100%;">
        <div class="card-body">
          <h5 class="card-title">공지사항</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;" @click="this.$router.push('./notice')">more</h6>
          <p class="card-text">
            <table class="table">
              <colgroup>
                <col width=10%>
                <col width=60%>
                <col width=30%>
            </colgroup>                 
                <thead class="table-dark">
                  <tr>
                    <th scope="col-sm-1">No</th>
                    <th scope="col-sm-10">제목</th>
                    <th scope="col-sm-1" >일자</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in nlists" v-bind:key="list.no" @click="detailView(list)">
                    <th scope="row">{{idx+1}}</th>
                    <td>{{ list.subject.substring(0,30) }}</td>
                    <td>{{ formatDate(list.wdate) }}</td>
                  </tr>               
                </tbody>
              </table>
  
          </p>
        </div>
      </div>
      <p></p>
      <div class="card" style="width: 100%;">
        <div class="card-body">
          <h5 class="card-title">분반자료</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;" @click="this.$router.push('./study')">more</h6>
          <p class="card-text">
            <table class="table">
              <colgroup>
                <col width=10%>
                <col width=60%>
                <col width=30%>
            </colgroup>              
              <thead class="table-dark">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">제목</th>
                  <th scope="col">일자</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, idx)  in slists" v-bind:key="list.no" @click="detailView(list)">
                  <th scope="row">{{idx + 1}}</th>
                  <td>{{ list.subject.substring(0,30) }}</td>
                  <td>{{ formatDate(list.wdate) }}</td>
                </tr>            
              </tbody>
            </table>
  
          </p>
        </div>
      </div>


        <!--  장기 결석자 -->
        <div class="card" style="width: 100%;">
          <div class="card-body">
            <h5 class="card-title" style="color: darkred;">장기 결석자<span style="font-size: 11pt;">(3주 이상)</span></h5>
            <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;" @click="this.$router.push('./notice')"></h6>
            <p class="card-text">
              <table class="table">             
                  <tbody>
                    <tr >
                      <td v-if="grade =='99'">
                        <span  >
                          <ul class="list-group" v-for="mlist in groupedItems" v-bind:key="mlist.no">
                            <li class="list-group-item" style="margin-bottom: 7px;">{{mlist[0]["gradename"]}} : <span v-for="slist in mlist" v-bind:key="slist.name">&nbsp;&nbsp;{{slist.name}}</span></li>
                          </ul>


                        </span>                      
                       </td>
                       <td v-else>
                        <span v-for="mlist in absentlist" v-bind:key="mlist.no">{{ mlist.name }}&nbsp;&nbsp;</span>                      
                       </td>                     
                    </tr>               
                  </tbody>
                </table>
    
          </p>
        </div>
      </div>
      <p></p>

      <!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ items.subject }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" v-html="getContent(items.contents)">        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">닫기</button>
      </div>
    </div>
  </div>
</div>

      <!-- 일정 상세 모달 -->
      <div class="modal fade" id="scheduleDetailModal" tabindex="-1" aria-labelledby="scheduleDetailModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="scheduleDetailModalLabel">{{ selectedDate ? formatFullDate(selectedDate) : '' }} 일정</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="selectedDateEvents.length === 0" class="text-center py-3">
                해당 날짜에 일정이 없습니다.
              </div>
              <div v-else class="schedule-list">
                <div v-for="(event, index) in selectedDateEvents" :key="index" class="schedule-item">
                  <h5>{{ event.title }}</h5>
                  <span class="category-badge" :style="{ background: getCategoryColor(event.category) }">{{ event.category || '기타' }}</span>
                  <div v-html="getContent(event.contents)"></div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button 
                v-if="selectedDateEvents.length > 0" 
                class="btn btn-sm btn-outline-primary mr-2" 
                @click="editSchedule(selectedDateEvents[0])"
              >수정</button>
              <button 
                v-if="selectedDateEvents.length > 0" 
                class="btn btn-sm btn-outline-danger mr-2" 
                @click="deleteSchedule(selectedDateEvents[0])"
              >삭제</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">닫기</button>
            </div>
          </div>
        </div>
      </div>

      <!-- 일정 추가/수정 모달 -->
      <div class="modal fade" id="scheduleFormModal" tabindex="-1" aria-labelledby="scheduleFormModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="scheduleFormModalLabel">{{ isEditMode ? '일정 수정' : '일정 추가' }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="scheduleDate">날짜</label>
                  <input 
                    type="date" 
                    class="form-control" 
                    id="scheduleDate" 
                    v-model="scheduleForm.date"
                    required
                  >
                </div>
                <div class="form-group">
                  <label for="scheduleCategory">카테고리</label>
                  <select 
                    class="form-control" 
                    id="scheduleCategory" 
                    v-model="scheduleForm.category"
                  >
                    <option value="">카테고리 선택</option>
                    <option value="예배">예배(부서)</option>
                    <option value="교육">교육(양육)</option>
                    <option value="행사">행사(프로그램)</option>
                    <option value="계획">연간(월간)계획</option>
                    <option value="기타">기타</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="scheduleTitle">제목</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    id="scheduleTitle" 
                    v-model="scheduleForm.title"
                    placeholder="일정 제목을 입력하세요"
                    required
                  >
                </div>
                <div class="form-group">
                  <label for="scheduleContents">내용</label>
                  <textarea 
                    class="form-control" 
                    id="scheduleContents" 
                    v-model="scheduleForm.contents"
                    rows="5"
                    placeholder="일정 내용을 입력하세요"
                  ></textarea>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
              <button type="button" class="btn btn-primary" @click="saveSchedule">저장</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
  
<script>
  import manageService from "../services/manageService";
  import dayjs from 'dayjs';
  import $ from "jquery";
  import '@fortawesome/fontawesome-free/css/all.css';

  export default {
      component: {
          dayjs
      },        
    data() {
      return {
        items: [],
        nlists: {},
        slists: {},          
        id: this.$store.state.id,
        grade: this.$store.state.grade,
        phone: this.$store.state.phone,
        name: this.$store.state.name,
        teacherid: this.$store.state.teacherid,
        role: this.$store.state.role,
        token: this.$store.state.token,  
        classid: this.$store.state.classid,    
        absentlist: {},    
        absentlist2: {},        
        
        // 달력 관련 데이터
        currentDate: new Date(),
        weekdays: ['일', '월', '화', '수', '목', '금', '토'],
        scheduleEvents: [],
        selectedDate: null,
        selectedDateEvents: [],
        
        // 일정 폼 데이터
        scheduleForm: {
          no: 0,
          date: dayjs().format('YYYY-MM-DD'),
          title: '',
          contents: '',
          writer: '',
          category: ''
        },
        isEditMode: false,
      }
    },
    computed:{
      isAuthenticated() {
        return this.$store.state.isAuthenticated;
       },        
      formatDate(){
          return (v)=>{
            return dayjs(v).format("YY.MM.DD");
            }
         },
      formatFullDate() {
        return (date) => {
          return dayjs(date).format("YYYY년 MM월 DD일");
        }
      },          
      groupedItems() {
        const result = Array.isArray(this.absentlist2);

        if(result){
          return this.absentlist2.reduce((groups, item) => {
            const group = groups[item.gradename] || [];
            group.push(item);
            groups[item.gradename] = group;
            return groups;
          }, {});
        } else {
          return null;
        }
      },
      currentMonthYear() {
        const year = this.currentDate.getFullYear();
        const month = this.currentDate.getMonth() + 1;
        return `${year}년 ${month}월`;
      },
      calendarDates() {
        const year = this.currentDate.getFullYear();
        const month = this.currentDate.getMonth();
        
        // 현재 월의 첫날과 마지막날
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        
        // 달력에 표시할 날짜 배열
        const dates = [];
        
        // 이전 달의 날짜 추가
        const firstDayOfWeek = firstDay.getDay();
        if (firstDayOfWeek > 0) {
          const prevMonthLastDay = new Date(year, month, 0);
          const prevMonthDays = prevMonthLastDay.getDate();
          
          for (let i = firstDayOfWeek - 1; i >= 0; i--) {
            const day = prevMonthDays - i;
            const date = new Date(year, month - 1, day);
            dates.push({
              date: date,
              day: day,
              currentMonth: false
            });
          }
        }
        
        // 현재 달의 날짜 추가
        for (let day = 1; day <= lastDay.getDate(); day++) {
          const date = new Date(year, month, day);
          dates.push({
            date: date,
            day: day,
            currentMonth: true
          });
        }
        
        // 다음 달의 날짜 추가
        const remainingDays = 42 - dates.length; // 6주 달력을 위해
        for (let day = 1; day <= remainingDays; day++) {
          const date = new Date(year, month + 1, day);
          dates.push({
            date: date,
            day: day,
            currentMonth: false
          });
        }
        
        return dates;
      }      
   },          
    methods:{
      getContent(text){
          if(text){
              // URL 패턴 정규식 - 공백이나 줄바꿈으로 구분된 URL만 매칭
              const urlRegex = /(https?:\/\/[^\s<]+)(?=\s|<|$)/g;
              
              // 먼저 URL을 클릭 가능한 링크로 변환한 후, 줄바꿈을 <br>로 변환
              return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>')
                         .replace(/(?:\r\n|\r|\n)/g, '<br />');
          }
      },
      detailView(item){
        this.items = item;
        $("#exampleModal").modal();
      },
      getData(){
        const data = {
          classid: this.classid,
        };
        manageService
            .getboardDashboard(data)
            .then((response) => {

            this.nlists = response.data.notice;
            this.slists = response.data.study;
            })
            .catch((e) => {
            console.log(e);
            });
      },

      getAbsent(){
        const data = {
          classid: this.classid,
          grade: this.grade,
        };
        manageService
            .getAbsentAll(data)
            .then((response) => {
              console.log(response);
            this.absentlist = response.data;     
            this.absentlist2 = response.data; 
            })
            .catch((e) => {
            console.log(e);
            });
      },

      // 달력 관련 메서드
      prevMonth() {
        const year = this.currentDate.getFullYear();
        const month = this.currentDate.getMonth();
        this.currentDate = new Date(year, month - 1, 1);
      },
      nextMonth() {
        const year = this.currentDate.getFullYear();
        const month = this.currentDate.getMonth();
        this.currentDate = new Date(year, month + 1, 1);
      },
      isToday(date) {
        const today = new Date();
        return (
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        );
      },
      hasEvents(date) {
        if (!date) return false;
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        return this.scheduleEvents.some(event => {
          const eventDate = dayjs(event.date).format('YYYY-MM-DD');
          return eventDate === formattedDate;
        });
      },
      getDateEvents(date) {
        if (!date) return [];
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        return this.scheduleEvents.filter(event => {
          const eventDate = dayjs(event.date).format('YYYY-MM-DD');
          return eventDate === formattedDate;
        });
      },
      getCategoryColor(category) {
        // 카테고리별 색상 정의
        const categoryColors = {
          '예배': '#ea4335', // 빨간색
          '교육': '#4285f4', // 파란색
          '행사': '#fbbc05', // 노란색
          '계획': '#34a853', // 초록색
          '기타': '#673ab7'  // 보라색
        };
        
        return categoryColors[category] || '#4285f4'; // 기본 파란색
      },
      viewDateEvents(date) {
        this.selectedDate = date;
        this.selectedDateEvents = this.getDateEvents(date);
        $("#scheduleDetailModal").modal();
      },
      addSchedule() {
        this.isEditMode = false;
        this.scheduleForm = {
          no: 0,
          date: this.selectedDate ? dayjs(this.selectedDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
          title: '',
          contents: '',
          writer: this.name,
          category: ''
        };
        $("#scheduleFormModal").modal();
      },
      editSchedule(event) {
        this.isEditMode = true;
        this.scheduleForm = {
          no: event.no,
          date: dayjs(event.date).format('YYYY-MM-DD'),
          title: event.title,
          contents: event.contents,
          writer: event.writer,
          category: event.category || ''
        };
        $("#scheduleDetailModal").modal('hide');
        $("#scheduleFormModal").modal();
      },
      deleteSchedule(event) {
        if (confirm('정말로 이 일정을 삭제하시겠습니까?')) {
          const data = {
            no: event.no,
            classid: this.classid,
          };
          
          manageService
            .deleteSchedule(data)
            .then((response) => {
              if (response.data[0] === true) {
                this.$toast.show("일정이 삭제되었습니다.", {
                  type: "success",
                  position: "top-right",
                });
                this.getSchedules();
                $("#scheduleDetailModal").modal('hide');
              } else {
                this.$toast.show("일정 삭제에 실패했습니다.", {
                  type: "warning",
                  position: "top-right",
                });
              }
            })
            .catch((e) => {
              console.log(e);
              this.$toast.show("일정 삭제 중 오류가 발생했습니다.", {
                type: "error",
                position: "top-right",
              });
            });
        }
      },
      saveSchedule() {
        // 폼 유효성 검사
        if (!this.scheduleForm.date || !this.scheduleForm.title) {
          this.$toast.show("날짜와 제목은 필수 입력사항입니다.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }
        
        const data = {
          no: this.scheduleForm.no,
          date: this.scheduleForm.date,
          title: this.scheduleForm.title,
          contents: this.scheduleForm.contents,
          userid: this.$store.state.id,
          writer: this.name,
          classid: this.classid,
          category: this.scheduleForm.category
        };
        
        manageService
          .saveSchedule(data)
          .then((response) => {
            if (response.data[0] === true) {
              this.$toast.show(this.isEditMode ? "일정이 수정되었습니다." : "일정이 추가되었습니다.", {
                type: "success",
                position: "top-right",
              });
              this.getSchedules();
              $("#scheduleFormModal").modal('hide');
            } else {
              this.$toast.show(this.isEditMode ? "일정 수정에 실패했습니다." : "일정 추가에 실패했습니다.", {
                type: "warning",
                position: "top-right",
              });
            }
          })
          .catch((e) => {
            console.log(e);
            this.$toast.show("일정 저장 중 오류가 발생했습니다.", {
              type: "error",
              position: "top-right",
            });
          });
      },
      canModifyEvent() {
        // 모든 사용자가 수정/삭제 가능하도록 설정
        return true; 
      },
      getSchedules() {
        const data = {
          classid: this.classid,
          year: this.currentDate.getFullYear(),
          month: this.currentDate.getMonth() + 1,
        };
        
        manageService
          .getSchedules(data)
          .then((response) => {
            this.scheduleEvents = response.data;
            
            // 선택된 날짜가 있으면 해당 날짜의 일정도 업데이트
            if (this.selectedDate) {
              this.selectedDateEvents = this.getDateEvents(this.selectedDate);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },
    },
    created() {
      this.getData();   
      this.getAbsent();
      this.getSchedules();
      //console.log("getisLogin",this.$store.state);  
  },      
  }

</script>
<style scoped>
  .table-dark {
  color: #000;
  background-color: #007bff80;
 }
 .table td, .table th {
  padding: 0.5rem;
 }
 .icon-container {
display: flex;
flex-wrap: wrap;
justify-content: space-around;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.icon img {
  margin-bottom: 10px;
}

/* 달력 스타일 */
.calendar-container {
  width: 100%;
  margin: 0 auto;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.weekday {
  padding: 8px;
}

.calendar-dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.date-cell {
  border: 1px solid #dee2e6;
  min-height: 80px;
  padding: 5px;
  position: relative;
  cursor: pointer;
}

.date-cell:hover {
  background-color: #f8f9fa;
}

.date-number {
  font-size: 14px;
  margin-bottom: 5px;
}

.other-month {
  color: #c0c0c0;
  background-color: #f9f9f9;
}

.today {
  background-color: #e8f0fe;
  font-weight: bold;
}

.has-events {
  position: relative;
}

.event-dots {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 2px;
}

.event-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #4285f4;
}

/* 반응형 조정 */
@media (max-width: 768px) {
  .date-cell {
    min-height: 60px;
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .calendar-days {
    font-size: 12px;
  }
  
  .date-cell {
    min-height: 40px;
    padding: 2px;
  }
  
  .date-number {
    font-size: 12px;
  }
}

/* 일정 상세 모달 스타일 */
.schedule-list {
  max-height: 400px;
  overflow-y: auto;
}

.schedule-item {
  border-bottom: 1px solid #dee2e6;
  padding: 10px 0;
}

.schedule-item:last-child {
  border-bottom: none;
}

.schedule-meta {
  color: #6c757d;
  margin-top: 8px;
}

.schedule-actions {
  margin-top: 10px;
}

.category-badge {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 12px;
  color: white;
  font-size: 12px;
  margin-bottom: 8px;
}
</style>